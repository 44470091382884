<!-- 餐厅配置-商品分类 -->

<template>
  <div class="produClassif main-cnt">
    <div class="title">分类列表</div>
    <div class="content">
      <common-table ref="classifiTable" tableHeight="calc(100vh - 325px)" :apiName="DinnerApi.getDinnerGoodTypeList"
        :columns="tableColumns" @edit="onEditBtn" @onDeleteBtn="onDeleteBtn"
        @onRelatedProductsBtn="onRelatedProductsBtn">
        <template #operate>
          <el-button type="primary" @click="showAddDialog"
            v-if="authData.indexOf('r_e61KUGUCRocV2ZfAvplGeSz6hP0W') != -1">
            新增分类
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 新增、编辑 -->
    <w-dialog ref="classifiDialog" class="classifi-dialog" :title="isEdit ? '编辑分类' : '新增分类'" width="55%"
      btnWidth="140px" top="5vh" :confirmText="isEdit ? '确认保存' : '确认新增'" @wConfirm="classifiConfirm">
      <el-form class="dining-form" ref="formRef" :model="classifiForm" :rules="rules" labelPosition="top">
        <el-form-item label="所属门店" prop="dr_id">
          <el-select v-model="classifiForm.dr_id" clearable placeholder="请选择所属门店" @change="storeChange">
            <el-option :label="item.dr_name" :value="item.dr_id" v-for="item in storeOptions" :key="item.dr_id">
            </el-option>
          </el-select>
        </el-form-item>

        <w-title :title_name="'分类信息'"></w-title>

        <el-form-item label="分类名称" prop="drgt_title">
          <el-input v-model="classifiForm.drgt_title" placeholder="请输入分类名称"></el-input>
        </el-form-item>

        <el-form-item label="关联商品"></el-form-item>

        <el-table class="ar-table" v-loading="loading" :data="tableDatas" height="360" style="width: 100%" stripe
          tooltip-effect="light" @selection-change="handleSelectionChange" :row-key="getRowKeys" ref="tableRef">
          <el-table-column type="selection" :reserve-selection="true" width="55" />
          <el-table-column property="drg_name" label="商品名称"></el-table-column>
          <el-table-column property="drg_price" label="单价"></el-table-column>
          <el-table-column property="drg_unit" label="单位"></el-table-column>
        </el-table>
      </el-form>
    </w-dialog>

    <!-- 关联商品 -->
    <w-dialog ref="commodiDialog" class="commodi-dialog" title="图册" width="41%" btnWidth="140px" top="15vh"
      :hideFooter="true">
      <common-table ref="commodiTable" tableHeight="500" :columns="commodiTableColumns" :ispaging="false"
        :tableData="commodiTableData">
      </common-table>
    </w-dialog>
  </div>
</template>
<script setup>
  import { ref, reactive, onMounted, computed, watch, nextTick, } from "vue";
  import { DinnerApi, } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox, } from "element-plus";
  import { useStore } from "vuex";
  import { h } from 'vue'

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "dr_name",
      label: "所属门店",
    },
    {
      prop: "drgt_title",
      label: "分类名称",
    },
    {
      type: "block",
      prop: "goods_count",
      label: "关联商品",
      active: "onRelatedProductsBtn",
      minWidth: 60,
      token: "r_uG85SPs1TVzOdtf0nUL2EYBgpWm7",
    },
    {
      prop: "drgt_order",
      label: "排序",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 190,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          token: "r_6T0F2UNhDwGFYeDauDpfA0q8q3DP",
          className: "theme-font-btn",
        },
        {
          name: "删除",
          action: "onDeleteBtn",
          token: "r_qT1iEZcKc7zvPu1YAFN2gifXmWhV",
          className: "red-font-btn",
        },
      ],
    },
  ]);

  const classifiTable = ref(null);  // 列表表格数据
  const storeOptions = ref([]);  // 所属门店选项
  const isEdit = ref(false);  // 是否新增
  const classifiDialog = ref(null);  // 新增、编辑弹框
  const formRef = ref(null);  // 表单对象
  const currentRow = ref(null);  // 当前操作行数据
  const tableRef = ref(null);  // 关联商品表格对象
  const tableDatas = ref([]);  // 关联商品表格数据
  const loading = ref(false);  // 加载状态
  const classifiForm = reactive({  // 表单数据对象
    dr_id: '', // 所属门店id
    drgt_title: '', // 分类名称
    gids: [], // 关联的商品id
  });
  const rules = reactive({  // 表单规则
    dr_id: [
      { required: true, message: "请选择所属门店", trigger: "change", },
    ],
    drgt_title: [
      { required: true, message: "请输入分类名称", trigger: "blur", },
    ],
  });
  const commodiDialog = ref(null);  // 关联商品对象
  const commodiTable = ref(null);  // 关联商品表格对象
  const commodiTableData = ref([]);  // 关联商品表格数据
  const commodiTableColumns = ref([
    {
      prop: "drg_name",
      label: "商品名称",
    },
    {
      prop: "drg_price",
      label: "单价",
    },
    {
      type: "image",
      prop: "drgf_path_text",
      label: "封面图",
      minWidth: 150,
    },
    {
      prop: "drg_unit",
      label: "单位",
    },
  ]);
  /**
   * 
   * 获取门店选项
   * 
   * **/
  const getStoreData = () => {
    DinnerApi.getRangDinnerRoom().then((res) => {
      if (res.Code === 200) {
        storeOptions.value = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 新增按钮
   * 
   * **/
  const showAddDialog = () => {
    isEdit.value = false;
    classifiForm.dr_id = '';
    classifiForm.drgt_title = '';
    classifiForm.gids = [];
    classifiDialog.value.show();
  };
  /**
   * 
   * 编辑按钮
   * 
   * **/
  const onEditBtn = (row) => {
    currentRow.value = row;
    isEdit.value = true;
    getDinnerGoodTypeInfo();
    classifiDialog.value.show();
  };
  /**
   * 
   * 获取详情
   * 
   * **/
  const getDinnerGoodTypeInfo = () => {
    DinnerApi.getDinnerGoodTypeInfo({ drgt_id: currentRow.value.drgt_id }).then((res) => {
      loading.value = false;
      if (res.Code === 200) {
        classifiForm.dr_id = res.Data.dr_id;
        classifiForm.drgt_title = res.Data.drgt_title;
        storeChange();
        if (res.Data.regoods.length > 0) {
          classifiForm.gids = res.Data.regoods;
          // 回显已关联商品
          res.Data.regoods.forEach(item => {
            tableRef.value.toggleRowSelection(item, true);
          })
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 选择门店获取关联商品
   * 
   * **/
  const storeChange = () => {
    loading.value = true;
    classifiForm.gids = [];
    tableRef.value.clearSelection();
    DinnerApi.getGoodbydrid({ dr_id: classifiForm.dr_id }).then((res) => {
      loading.value = false;
      if (res.Code === 200) {
        tableDatas.value = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 表格唯一标识
   * 
   * **/
  const getRowKeys = (row) => {
    return row.drg_id;
  }
  /**
   * 
   * 选择表格
   * 
   * **/
  const handleSelectionChange = (data) => {
    classifiForm.gids = data;
  }
  /**
  * 
  * 新增、编辑确认
  * 
  * **/
  const classifiConfirm = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        let selectArr = [];
        if (classifiForm.gids.length > 0) {
          classifiForm.gids.forEach(item => {
            selectArr.push(item.drg_id);
          })
        }
        let params = {}, url = '';
        if (isEdit.value) {
          // 编辑
          url = 'editProductCat';
          params = {
            drgt_id: currentRow.value.drgt_id,
            drgt_dr_id: classifiForm.dr_id,
            drgt_title: classifiForm.drgt_title,
            gids: selectArr.join(","),
          };
        } else {
          // 新增
          url = 'addProductCat';
          params = {
            dr_id: classifiForm.dr_id,
            drgt_title: classifiForm.drgt_title,
            gids: selectArr.join(","),
          };
        }
        // console.log("提交", url, params);
        classifiDialog.value.isLoading = true;
        DinnerApi[url](params).then((res) => {
          classifiDialog.value.isLoading = false;
          let text = isEdit.value ? "编辑" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`分类${text}成功！`);
            classifiDialog.value.close();
            classifiTable.value.tableLoad();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
    });
  };
  /**
  * 
  * 删除按钮
  * 
  * **/
  const onDeleteBtn = (row) => {
    ElMessageBox.confirm("确认删除该内容吗?", "温馨提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      message: h('p', null, [
        h('div', null, `你确定要删除分类 ${row.drgt_title}？`),
        h('div', null, '分类一经删除不可恢复。'),
      ]),
    })
      .then(() => {
        DinnerApi.deleteDinnerGoodType({ drgt_id: row.drgt_id }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success('删除成功！');
            classifiTable.value.tableLoad();
          } else {
            ElMessage.error(res.Message);
          }
        });
      })
      .catch(() => { });
  }
  /**
  * 
  * 关联商品
  * 
  * **/
  const onRelatedProductsBtn = (row) => {
    DinnerApi.getGoodsBydtid({ drgt_id: row.drgt_id, drgt_dr_id: row.drgt_dr_id }).then((res) => {
      if (res.Code === 200) {
        commodiTableData.value = res.Data;
        commodiDialog.value.show();
        nextTick(() => {
          commodiTable.value.tableLoad();
        })
      } else {
        ElMessage.error(res.Message);
      }
    });
  }

  onMounted(() => {
    classifiTable.value.tableLoad();
    getStoreData();
  });
</script>

<style lang="scss">
  .produClassif {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }

    .ar-table .el-table__cell {
      padding: 9px 0;
    }

    .ar-table th.el-table__cell {
      background-color: var(--search-bg-color);
      padding: 9px 0;
      font-size: 15px;
      color: var(--text-color);
    }

    .ar-table td.el-table__cell div span {
      font-size: 14px;
    }

    .classifi-dialog {
      .el-dialog {
        min-width: 840px;

        .el-dialog__body {
          padding: 15px 15px 40px;

          .dining-form {

            .el-form-item {
              width: 33.3%;
              /* padding: 20px 15px 0;
              margin-bottom: 0; */

              .el-form-item__content {
                .el-date-editor {
                  width: 100%;

                  .el-range-input {
                    background-color: transparent;
                  }
                }
              }
            }

            .el-form-item.block {
              width: 100%;
            }

            .el-divider {
              margin: 30px 0 0 15px;
              width: calc(100% - 30px);
            }
          }
        }
      }
    }
  }
</style>